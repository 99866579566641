<template>
  <v-container>
    <h2>{{ $t("message.shop-holiday-page-title") }}</h2>
    <v-row class="d-flex flex-row align-center" style="margin-top: 1%">
      <v-col cols="12" lg="2" md="2" sm="12">
        <div>
          <span>{{ $t("message.shop") }}</span>
        </div>
      </v-col>
      <v-col cols="12" lg="5" md="5">
        <v-autocomplete
          v-model="shopId"
          :items="shopList"
          item-text="store_name"
          item-value="id"
          @change="fetchHolidayDetail"
          outlined
        >
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="3">
        <span>{{ $t("message.holiday-setting") }}</span>
      </v-col>
      <v-col md="8">
        <!-- fetch holiday data -->
        <v-row
          style="padding-top: 0px"
          v-for="(holiday, i) in shopHolidayDetail.dates"
          :key="i"
        >
          <v-col cols="12" sm="5" md="3" style="padding: 0px">
            <v-menu
              v-model="menuHoliday[i]"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="datesHoliday[i]"
                  prepend-icon="mdi-calendar"
                  style="padding-top: 0px;"
                  readonly
                  width="fit-content"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="datesHoliday[i]"
                @input="menuHoliday[i] = false"
                @change="changeDateHoliday(i)"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col>
            <v-icon
              color="red"
              small
              @click.prevent="deleteRowHoliday(holiday.id)"
            >
              mdi-delete
            </v-icon>
          </v-col>
        </v-row>

        <v-btn rounded color="primary" @click.prevent="addRowHoliday()">
          <div v-if="loaderShopHoliday" style="margin-right:10px">
            <v-progress-circular
              indeterminate
              color="white"
              size="11"
              left
            ></v-progress-circular>
          </div>
          <div v-else>
            <v-icon left>
              mdi-plus
            </v-icon>
          </div>
          {{ $t("message.add") }}
        </v-btn>
      </v-col>
    </v-row>
    <!-- Active Day -->
    <v-row style="margin-top: 5%" v-if="shopActiveDetail">
      <v-col md="3">
        <span>{{ $t("message.active-day") }}</span>
      </v-col>
      <v-col md="8">
        <!-- fetch active data -->
        <v-row
          style="padding-top: 0px"
          v-for="(activeShop, j) in shopActiveDetail.dates"
          :key="j"
        >
          <v-col cols="12" sm="5" md="3" style="padding: 0px; padding-bottom: 0px;">
            <v-menu
              v-model="menuActive[j]"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="datesActive[j]"
                  prepend-icon="mdi-calendar"
                  style="padding-top: 0px; padding-bottom: 0px;"
                  readonly
                  width="fit-content"
                  v-bind="attrs"
                  v-on="on"
                  class="holiday"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="datesActive[j]"
                @input="menuActive[j] = false"
                @change="changeDateActive(j)"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col>
            <v-icon
              color="red"
              small
              @click.prevent="deleteRowActive(activeShop.id)"
            >
              mdi-delete
            </v-icon>
          </v-col>
        </v-row>

        <v-btn rounded color="primary" @click.prevent="addRowActive()">
          <div v-if="loaderShopActive" style="margin-right:10px">
            <v-progress-circular
              indeterminate
              color="white"
              size="11"
              left
            ></v-progress-circular>
          </div>
          <div v-else>
            <v-icon left>
              mdi-plus
            </v-icon>
          </div>
          {{ $t("message.add") }}
        </v-btn>
      </v-col>
    </v-row>

    <div class="fixed-div">
      <v-row cols="12" class="row-btn">
        <v-col cols="4" md="9" xs="1" class="btn-col">
          <v-btn
            class="btn-back-class"
            rounded
            color="secondary"
            dark
            @click.prevent="goBack"
          >
            {{ $t("message.back-button") }}
          </v-btn>
        </v-col>
        <v-col cols="4" md="1" xs="1" class="btn-col">
          <v-btn
            v-if="shopId"
            class="btn-edit-class"
            rounded
            color="primary"
            dark
            @click.prevent="update()"
          >
            {{ $t("message.update-button") }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import Swal from "sweetalert2";

export default {
  name: "ShopHoliday",
  data: () => ({
    items: ["Tajimi 1", "Tajimi 2", "Tajimi 3"],
    menuHoliday: [],
    menuActive: [],
    modal: false,
    menu2: false,
    shopId: null,
    datesHoliday: [],
    datesActive: [],
    loaderShopActive: false,
    loaderShopHoliday: false,
    isAddHoliday: false,
    isAddActive: false,
  }),
  methods: {
    goBack() {
      this.$router.go("-1");
    },
    async fetchHolidayDetail() {
      //clear date
      this.datesHoliday = [];
      this.datesActive = [];
      this.loaderShopHoliday = true;

      await this.$store.dispatch("shopHoliday_module/fetchShopHolidayDetail", {
        shopId: this.shopId,
      });
      let shopHoliday = this.$store.state.shopHoliday_module.shopHolidayDetail;
      let shopActive = this.$store.state.shopHoliday_module.shopActiveDetail;

      this.getHolidayDates();
      this.getActiveDates();

      if (this.isAddHoliday) {
        shopHoliday.dates.push({
          id: 0,
          date: null,
        });
      }
      if(this.isAddActive) {
        shopActive.dates.push({
          id: 0,
          date: null,
        });
      }
      this.isAddHoliday = false;
      this.isAddActive = false;
      this.loaderShopHoliday = false;
    },
    getHolidayDates() {
      let shopHoliday = this.$store.state.shopHoliday_module.shopHolidayDetail;

      shopHoliday.dates.forEach((element) => {
        this.datesHoliday.push(
          new Date(
            new Date(element.date) - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .substr(0, 10)
        );
        this.menuHoliday.push(false);
      });
    },
    getActiveDates() {
      let shopActive = this.$store.state.shopHoliday_module.shopActiveDetail;

      shopActive.dates.forEach((element) => {
        this.datesActive.push(
          new Date(
            new Date(element.date) - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .substr(0, 10)
        );
        this.menuActive.push(false);
      });
    },
    async fetchShopList() {
      await this.$store.dispatch("shop_module/fetchShopAll");
      let resStoreId = this.$store.state.shop_module.shopAllResult;

      this.shopId = resStoreId[0].id;

      await this.$store.dispatch("shopHoliday_module/fetchShopHolidayDetail", {
        shopId: this.shopId,
      });

      this.getHolidayDates();
      this.getActiveDates();
    },
    changeDateHoliday(index) {
      this.$store.dispatch("shopHoliday_module/changeDateHoliday", {
        index: index,
        date: this.datesHoliday[index],
      });
    },
    changeDateActive(index) {
      this.$store.dispatch("shopHoliday_module/changeDateActive", {
        index: index,
        date: this.datesActive[index],
      });
    },
    alert() {
      const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: false,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "error",
        title: "日付が重複しています",
      });
    },
    async addRowHoliday() {
      this.loaderShopHoliday = true;
      let resp = await this.$store.dispatch(
        "shopHoliday_module/addRowHoliday",
        { shopId: this.shopId }
      );
      if (resp) {
        this.isAddHoliday = true;
        this.fetchHolidayDetail();
      } else if (resp == false) {
        //akan masuk false jika tanggal yang di masukkan sama dengan sebelumnya
        this.fetchHolidayDetail();
        this.alert()
      }
      this.loaderShopHoliday = false;
    },
    async addRowActive() {
      this.loaderShopActive = true;
      let resp = await this.$store.dispatch("shopHoliday_module/addRowActive", {
        shopId: this.shopId,
      });
      if (resp) {
        this.isAddActive = true;
        this.fetchHolidayDetail();
      } else if (resp == false) {
        //akan masuk false jika tanggal yang di masukkan sama dengan sebelumnya

        this.fetchHolidayDetail();
        this.alert()
        
      }
      this.loaderShopActive = false;
    },
    async deleteRowHoliday(id) {
      let resp = await this.$store.dispatch("shopHoliday_module/deleteRow", {
        id: id,
        shopId: this.shopId,
      });
      this.fetchHolidayDetail();
    },
    async deleteRowActive(id) {
      let resp = await this.$store.dispatch("shopHoliday_module/deleteRow", {
        id: id,
        shopId: this.shopId,
      });
      this.fetchHolidayDetail();
    },
    async update() {
      let shopActive = this.shopActiveDetail;
      let shopHoliday = this.shopHolidayDetail;
      let payload = {
        store_id: this.shopId,
        holiday: {
          type: 1,
          dates: shopHoliday.dates,
        },
        activeday: {
          type: 2,
          dates: shopActive.dates,
        },
      };
      let resp = await this.$store.dispatch(
        "shopHoliday_module/updateShopHoliday",
        payload
      );
      if (resp == false) {
        this.alert()
      }
      this.fetchHolidayDetail();
    },
  },
  computed: {
    shopList() {
      return this.$store.state.shop_module.shopAllResult;
    },
    shopHolidayDetail() {
      return this.$store.state.shopHoliday_module.shopHolidayDetail;
    },
    shopActiveDetail() {
      return this.$store.state.shopHoliday_module.shopActiveDetail;
    },
  },
  created() {
    this.fetchShopList();
    this.fetchHolidayDetail();
  },
};
</script>

<style>
.fixed-div {
  margin-top: 20%;
  bottom: 0px;
  right: 50%;
}
</style>
